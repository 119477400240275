import React, {memo, useEffect} from "react"
import {PullToRefresh} from "antd-mobile"
import {userLogin} from "@/model/user"
import {initWechat} from "@/plugin/wechat/official"
import "./index.less"

const Layout = ({children, bgcolor, refresh, needLogin = true, needCopyRight = false, needWechat = true}) => {
    useEffect(() => {
        needLogin && userLogin()
    }, [needLogin])

    useEffect(() => {
        needWechat && initWechat(window.location.href)
    }, [needWechat])

    const handleRefresh = () => {
        refresh && refresh()
    }

    return (<div className="container-layout" style={{background: bgcolor ? bgcolor : "#F9F9F9"}}>
        <PullToRefresh onRefresh={() => handleRefresh()}>
            {children}
        </PullToRefresh>
        {
            needCopyRight &&
            <div className="copyright">
                <div className="copyright-ctd">西安名企交友信息科技有限公司</div>
                <a className="copyright-filing" href="https://beian.miit.gov.cn/">陕ICP备2021000048号-6</a>
            </div>
        }
    </div>)
}
export default memo(Layout)
