import {ApiJsConfig} from "@/api/wechat";

export async function initWechat(url) {
    const data = await ApiJsConfig({url: url})
    window.$wx.config({...data, jsApiList: data.jsApiList.concat(["chooseWXPay"])})
}

/**
 * @param data
 * @param func
 * @param cancel
 */
export function invokePayment(data, func, cancel) {
    window.$wx.chooseWXPay({
        timeStamp: data.timeStamp + "", // 时间戳，必填（后台传回）
        nonceStr: data.nonceStr, // 随机字符串，必填（后台传回）
        package: data.package, // 统一下单接口返回的 prepay_id 参数值，必填（后台传回）
        signType: "MD5", // 签名算法，非必填，（预先约定或者后台传回）
        paySign: data.paySign, // 签名 ，必填 （后台传回）
        success: function (res) { // 成功后的回调函数
            func(res)
        },
        fail: function (err) {
            cancel(err)
        }
    })
}
