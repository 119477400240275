import {applyMiddleware, compose, createStore} from "redux"
import thunkMiddleware                         from "redux-thunk"
import rootReducer                             from "./reducers"

const composeEnhancers = compose

const middlewares = [
    thunkMiddleware,
]

// if (process.env.NODE_ENV === "development") {
//     middlewares.push(require("redux-logger").createLogger())
// }

const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
)

export default function configStore() {
    return createStore(rootReducer, enhancer)
}
