import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Layout from "@/layout";
import {Image} from "antd-mobile";
import {RightOutline} from "antd-mobile-icons"
import {ApiItems} from "@/api/help";
import {getImageSrc} from "@/filters/modules/system";
import "./index.less"

export default function Help() {
    const [items, setItems] = useState([])

    const history = useHistory()
    const configData = useSelector((state) => state.app.configData)

    const fetchData = useCallback(async () => {
        const data = await ApiItems()
        if (data) {
            setItems(data)
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (<Layout bgcolor="#F8F9FC" needLogin={false} needWechat={false}>
        <div className="page-help">
            {items.map((item, index) => (<div key={index} className="help-card">
                <div className="help-card-title">
                    <div className="help-card-title-icon">
                        <Image src={getImageSrc(item.icon, configData.static_url)} fit="cover"/>
                    </div>
                    <div className="help-card-title-text">
                        {item.name}
                    </div>
                </div>
                <div className="help-card-questions">
                    {
                        item.questions.map((question, key) => (
                            <div
                                key={key}
                                className="help-card-questions-item"
                                onClick={() => history.push("/help/" + question.id)}
                            >
                                <div className="help-card-questions-item-title">
                                    {question.title}
                                </div>
                                <div className="help-card-questions-item-icon">
                                    <RightOutline/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>))}

        </div>
    </Layout>)
}
