import {combineReducers} from "redux"
import app               from "@/store/reducers/app"
import user              from "@/store/reducers/user"
import overall           from "@/store/reducers/overall"

export default combineReducers({
    app    : app,
    user   : user,
    overall: overall,
})
