import { ImageViewer } from "antd-mobile"

export const checkUserSocialSubscribe = (infoUser) => {
    if (!infoUser) {
        return false
    }
    if (!infoUser.hasOwnProperty("socials")) {
        return false
    }
    if (!infoUser.socials.hasOwnProperty(process.env.PLATFORM)) {
        return false
    }
    if (!infoUser.socials[process.env.PLATFORM].hasOwnProperty(process.env.PROVIDER)) {
        return false
    }
    return !!infoUser.socials[process.env.PLATFORM][process.env.PROVIDER]
}

export const getUriJson = (url) => {
    var params = {}
    if (url.indexOf("?") !== -1) {
        params = buildUriToJson(url.split("?")[1])
    }
    return params
}

export const buildUriToJson = (uri) => {
    var params = {}
    const search = uri.split("&")
    for (var i = 0; i < search.length; i++) {
        params[search[i].split("=")[0]] = search[i].split("=")[1]
    }
    return params
}

export const buildJsonToString = (obj) => {
    let uri = ""
    for (var i = 0; i < Object.keys(obj).length; i++) {
        uri += Object.keys(obj)[i] + "=" + obj[Object.keys(obj)[i]] + "&"
    }
    return uri.slice(0, -1);
}

export const isUrl = (url) => {
    const reg = /^(https?:)?\/\//i
    return reg.test(url)
}

export const previewImage = (current, urlList = [], static_url = "") => {
    const reg = /^(https?:)?\/\//i
    const urls = []
    let initIndex = 0
    if (urlList.length) {
        for (let i = 0; i < urlList.length; i++) {
            if (!reg.test(urlList[i])) {
                urls.push(static_url + urlList[i])
            }
            if (urlList[i] === current) {
                initIndex = i
            }
        }
    }
    ImageViewer.Multi.show({ images: urls, defaultIndex: initIndex })
}

export const processChooseImage = (count, callback) => {

}

export const processUpload = async (directory, filePath, callback, param) => {

}

export const isValidVerificationCode = (code) => {
    const reg = /^[a-zA-Z0-9]{12,18}$/
    return reg.test(code)
}


export const isValidIDCard = (idCard) => {
    // 正则表达式匹配身份证号
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

    if (!reg.test(idCard)) {
        return false;// 不符合格式要求
    }

    // 进一步验证身份证号是否合法
    if (idCard.length === 15) {
        return true; // 15位身份证号不再验证合法性
    } else if (idCard.length === 18) {
        const arr = idCard.split('')
        // Wi
        const Wi = [
            7, 9, 10, 5, 8, 4, 2, 1, 6,
            3, 7, 9, 10, 5, 8, 4, 2, 1
        ]
        // 校验码
        const ValCodeArr = [
            '1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'
        ]
        let total = 0
        for (let i = 0; i < 17; i++) {
            total += parseInt(arr[i], 10) * Wi[i];
        }
        const valCodePosition = total % 11;
        if (arr[17].toUpperCase() !== ValCodeArr[valCodePosition]) {
            return false
        }
    }

    return true
}
