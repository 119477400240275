import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "@/layout";
import { ApiDetail } from "@/api/help";
import "./index.less"

export default function Detail () {
    const [detail, setDetail] = useState({ title: "", content: "" })

    const { id } = useParams()

    const fetchData = useCallback(async () => {
        if (id) {
            const data = await ApiDetail({ id: id })
            setDetail(data)
        }
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (<Layout bgcolor="#fff" needLogin={false} needWechat={false}>
        <div className="page-help-detail">
            <div className="help-title">
                {detail.title}
            </div>
            <div className="help-content" dangerouslySetInnerHTML={{ __html: detail.content }} />
        </div>
    </Layout>)
}
