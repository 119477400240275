import dayjs from "dayjs"
import dayBetween from "dayjs/plugin/isBetween"
import dayRelative from "dayjs/plugin/relativeTime"
import customParseFormat from "dayjs/plugin/customParseFormat"
import dayZh from "dayjs/locale/zh-cn"

dayjs.extend(dayBetween)
dayjs.extend(dayRelative)
dayjs.extend(customParseFormat)
dayjs.locale(dayZh)

export function filterPrice(price) {
    if (price) {
        return price / 100
    } else {
        return "0"
    }
}

export function calculateDiscount(originPrice, price) {
    if (price) {
        return parseInt(price / originPrice * 10)
    } else {
        return 0
    }
}

// 图片的url拼接
export const getImageSrc = (imageSrc, staticUrl) => {
    const reg = /^(https?:)?\/\//i
    if (reg.test(imageSrc)) {
        return imageSrc
    } else {
        return staticUrl + imageSrc
    }
}

export function getUrlParam() {
    var urlParam = window.location.search.substr(1);   //页面参数
    var arr = {};
    if (urlParam !== "") {
        var urlParamArr = urlParam.split("&"); //将参数按照&符分成数组
        for (var i = 0; i < urlParamArr.length; i++) {
            var paramArr = urlParamArr[i].split("="); //将参数键，值拆开
            arr[paramArr[0]] = paramArr[1];
        }
    }
    return arr
}

export function urlDelParam(url, paramKey) {
    var urlParam = window.location.search.substr(1);   //页面参数
    var beforeUrl = url.substr(0, url.indexOf("?"));   //页面主地址（参数之前地址）
    var nextUrl = "";

    var arr = [];
    if (urlParam !== "") {
        var urlParamArr = urlParam.split("&"); //将参数按照&符分成数组
        for (var i = 0; i < urlParamArr.length; i++) {
            var paramArr = urlParamArr[i].split("="); //将参数键，值拆开
            //如果键雨要删除的不一致，则加入到参数中
            if (paramArr[0] !== paramKey) {
                arr.push(urlParamArr[i]);
            }
        }
    }
    if (arr.length > 0) {
        nextUrl = "?" + arr.join("&");
    }
    url = beforeUrl + nextUrl;
    return url;
}

export function filterTime(time, cFormat = "") {
    if (!cFormat) {
        const timeYear = dayjs.unix(time).year()
        const nowYear = dayjs().year()
        if (timeYear === nowYear) {
            cFormat = "M月D日 HH:mm:ss"
        } else {
            cFormat = "YYYY年M月D日 HH:mm:ss"
        }
    }
    return dayjs.unix(time).format(cFormat)
}

export function getDateObject(dateString) {
    return dayjs(dateString, 'YYYY-MM-DD').toDate()
}

export function formatDate(date) {
    return dayjs(date).format("YYYY-MM-DD")
}

export function getDate() {
    return dayjs().format("YYYY-MM-DD")
}

/**
 *
 * @param birthday
 * @return {string}
 */
export const generateConstellation = (birthday) => {
    const MoBirthday = dayjs(birthday)
    const moBirthdayYear = MoBirthday.year()
    if (MoBirthday.isBetween(moBirthdayYear + "-01-20", moBirthdayYear + "-02-18", "day")) {
        return "aquarius"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-02-19", moBirthdayYear + "-03-20", "day")) {
        return "pisces"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-03-21", moBirthdayYear + "-04-19", "day")) {
        return "aries"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-04-20", moBirthdayYear + "-05-20", "day")) {
        return "taurus"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-05-21", moBirthdayYear + "-06-21", "day")) {
        return "gemini"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-06-22", moBirthdayYear + "-07-22", "day")) {
        return "cancer"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-07-23", moBirthdayYear + "-08-22", "day")) {
        return "leo"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-08-23", moBirthdayYear + "-09-22", "day")) {
        return "virgo"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-09-23", moBirthdayYear + "-10-23", "day")) {
        return "libra"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-10-24", moBirthdayYear + "-11-22", "day")) {
        return "scorpio"
    } else if (MoBirthday.isBetween(moBirthdayYear + "-11-23", moBirthdayYear + "-12-21", "day")) {
        return "sagittarius"
    } else {
        return "capricornus"
    }
}


export const birthDetail = (birthday) => {
    const result = {
        age: "", constellation: ""
    }
    if (birthday) {
        const moNowYear = dayjs().year()
        const moNowMonth = dayjs().month() + 1
        const moNowDay = dayjs().date()

        const MoBirthday = dayjs(birthday)
        const moBirthdayYear = MoBirthday.year()
        const moBirthdayMonth = MoBirthday.month() + 1
        const moBirthdayDay = MoBirthday.date()

        let age = moNowYear - moBirthdayYear

        if (moNowMonth < moBirthdayMonth) {
            age = age - 1
        } else if (moNowMonth === moBirthdayMonth) {
            if (moNowDay < moBirthdayDay) {
                age = age - 1
            }
        }
        result.age = age + "岁"
    } else {
        result.age = "保密"
    }
    const constellations = {
        aquarius: "水瓶座",
        pisces: "双鱼座",
        aries: "白羊座",
        taurus: "金牛座",
        gemini: "双子座",
        cancer: "巨蟹座",
        leo: "狮子座",
        virgo: "处女座",
        libra: "天秤座",
        scorpio: "天蝎座",
        sagittarius: "射手座",
        capricornus: "摩羯座"
    }
    result.constellation = constellations[generateConstellation(birthday)]
    return result
}

export function getAge(birthday) {
    if (birthday) {
        return dayjs().diff(birthday, 'year');
    }
    return 0;
}

export const updateCascaderSelected = (data, id, itemProps) => {
    for (const dataKey in data) {
        if (data[dataKey].hasOwnProperty("children") && data[dataKey].children.length > 0) {
            updateCascaderSelected(data[dataKey].children, id, itemProps);
        }
        if (data[dataKey].value === id) {
            data[dataKey] = {...data[dataKey], ...itemProps};
            for (const val in data[dataKey]) {
                if (data[dataKey][val] === null) {
                    delete data[dataKey][val]
                }
            }
        }
    }
};

export function transferCascaderOptions(data) {
    return data.map(item => ({
        value: item.id, label: item.name, children: [], has_children: item.has_children
    }));
}
