import * as VAL from "@/store/constants/app"

const localStorage = window.localStorage

const STATE = {
    loading     : false,
    configData  : {},
    locationInfo: JSON.parse(localStorage.getItem("location-info")) || {},
}

type ACTION = {
    type: string,
    data: any
}

export default function app(state = STATE, action: ACTION) {
    switch (action.type) {
        case VAL.LOADING:
            return {...state, loading: action.data}
        case VAL.CONFIGDATA:
            return {...state, configData: action.data}
        case VAL.LOCATIONINFO:
            localStorage.setItem("location-info", JSON.stringify(action.data))
            return {...state, locationInfo: action.data}
        default:
            return state
    }
}
