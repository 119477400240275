import * as VAL from "@/store/constants/overall"

const STATE = {
    showLogin: false,
}

type ACTION = {
    type: string,
    data: any
}

export default function overall(state = STATE, action: ACTION) {
    switch (action.type) {
        case VAL.SHOWLOGIN:
            return {...state, showLogin: action.data}
        default:
            return state
    }
}
